import React,{useState,useEffect} from 'react';
import Pagination from "react-js-pagination";
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,

  Heading
} from '@chakra-ui/react'
import { Input, InputGroup, InputRightElement ,IconButton } from '@chakra-ui/react';
import { SearchIcon,CloseIcon } from '@chakra-ui/icons'; 

import CustomModal from '../components/CustomModal'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../helpers';
import moment from 'moment';
import Header from '../components/Header'
import { getUserFromToken } from '../components/authHelper';

function List() {
  const [list , setList]= useState([])
  const [listTrans, setListTrans]= useState([])
   const [date , setDate]= useState('')

  const [loading,setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 
const [searchValue, setSearchValue] = useState('');

  useEffect(()=>{
     const user = getUserFromToken();
    const id = user.customer_id   
    loadTrans(id,date,searchValue);

   
  },[date])

  const loadTrans = async(id,date="",searchValue="") =>{
    setLoading(true)
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : "";
    const url = `${process.env.REACT_APP_API_URL}/transaction/list?customer_id=${id}&date=${formattedDate}&message_id=${searchValue}`;
    const data  = await fetchWrapper.get(url)
    setListTrans(data)
    setList(data.data)
    console.log(data)
    setListCount(data.count)
    setLoading(false)
  }

  const onChangePaginate = (pageNumber) => {
      setActivePage(pageNumber);
      // to set offset
      // console.log(pageNumber);
  };

  

  const handleSearchClick = () => {
  const user = getUserFromToken();
    const id = user.customer_id;
    loadTrans(id, date, searchValue);
    // setSearchValue('')
  };

  const clearSearch = () => {
  const user = getUserFromToken();
    const id = user.customer_id;
    
    setSearchValue('')
    loadTrans(id, date);
  };


  return(
    <>

    {loading &&
        <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
          <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }
    <Header/>
      <div className="body_section">
       
        
        <HStack justifyContent="space-between" mb="25px" w="100%">
          <Box w="50%">
            <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Billing</Text>
          </Box>
          <Box w="50%">
          <HStack w="100%" justifyContent="flex-end">
            <Box w="395px">
              <InputGroup>
                <Input type="text" placeholder="Search using Message ID or Phone No" 
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />

                <InputRightElement width="unset">
                <IconButton 
                  aria-label="Search"
                  icon={<SearchIcon color="gray.300" />} 
                  onClick={handleSearchClick}
                  variant="link"
                  pointerEvents="auto"
                />
                <IconButton
                aria-label="Clear"
                icon={<CloseIcon color="gray.300" />}
                onClick={clearSearch}
                variant="link"
                pointerEvents="auto"
              />
              </InputRightElement>

              </InputGroup>
            </Box>
            <Box>
              <Input type="date"  value={date} onChange={(e) => setDate(e.target.value)} />
            </Box>
          </HStack>
          </Box> 
        </HStack>


        <Box className="analytics_panel_section">
              <HStack mb="30px" >
                <Box className="analytics_box_section">
                  <Heading>€ {listTrans?.totalCR}</Heading>
                
                  <Text>Total Top-up <span>(Till Date)</span></Text>
                </Box>

                <Box className="analytics_box_section">
                  <Heading>€ {listTrans?.totalDR?.toFixed(4)}</Heading>
                
                  <Text>Total Used <span>(Till Date)</span></Text>
                </Box>

                <Box className="analytics_box_section">
                  <Heading>€ {listTrans?.total?.toFixed(4)}</Heading>
                
                  <Text>Current Balance</Text>
                </Box>
              </HStack>
        </Box>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Customer</Th>
                  <Th>Transcition Descpition</Th>
                  <Th>Transcition Type</Th>
                   <Th>Amount </Th>
                  
                  <Th>Status</Th>
                  <Th>Date</Th>
                  <Th></Th>



                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                  {list.map((rs,index)=>{
                    return(
                      <>
                          <Tr> 
                            <Td>{rs?.Customer?.business_name}</Td>
                           
                            <Td>{rs.trans_desc}</Td>
                            <Td>{rs.trans_type}</Td>
                            <Td>{rs.amount}</Td>
                            

                            <Td>
                            <div style={{color:rs?.Status?.color}}>{rs?.Status?.msg_status == 0 ? 'Failed' : 'Success'}</div>
                              {rs?.Status?.name}
                            </Td>

                            <Td>{moment(rs.created_at).format('DD MMMM YYYY')}</Td>
                            
                            <Td style={{color:"#0000EE"}}>
                              <Menu>
                                <MenuButton fontSize="14px" >
                                Action
                                  
                                    <ChevronDownIcon />
                                </MenuButton>

                                <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                  <MenuItem  h="16px" fontSize="12px">Edit</MenuItem>
                                   <MenuDivider />
                                  <MenuItem h="16px" fontSize="12px"  >Editable</MenuItem>
                                  
                                </MenuList>
                              </Menu>
                            </Td>
                          </Tr>
                      </>
                    )
                  })}

               
                            
                      
            
              </Tbody>
             
              
            </Table>
             <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={100}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
            
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default List;